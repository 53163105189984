import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
require('es6-promise').polyfill()
require('isomorphic-fetch')
const Success = ({ location }) => {
	const { search } = location
	const token = search.substr(7)
	if (token) {
		localStorage.setItem('token', JSON.stringify(token))
		navigate('/my-account')
	}
	console.log('extraced token', token)
	return ''
}

export default Success
